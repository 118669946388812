.mirasvit-credit {
    &__title {
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        margin: 0;
        text-transform: none;
    }

    &__credit-controls {
        display: flex;
        align-items: flex-start;
        margin-bottom: $spacer--medium;
        padding: $spacer--semi-medium 0;
    }

    &__credit-controls &__content-input {
        width: 80%;
        margin: 0;
    }
}
