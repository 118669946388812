.minicart-product {
    &__qty {
        align-items: flex-end;
    }

    &__input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mq($screen-s) {
            flex-direction: row;
            align-items: center;
        }
    }
}
