.payment-methods {
    .payment-icon.affirm-payment-icon {
        top: calc(50% + #{$spacer--extra-small});
        transform: translateY(-50%);
        height: unset;
    }
}

.affirm-modal-trigger {
    .__affirm-logo,
    .affirm-ala-price {
        margin-left: $spacer--extra-small;
    }
}
