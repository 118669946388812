.content-block {
    $root: &;

    &--secondary {
        #{$root}__heading {
            padding: $spacer--semi-medium 0 60px;
        }
    }

    .paragraph {
        line-height: 1.71;

        @include mq($screen-m) {
            line-height: 2;
        }
    }
}
