.toolbar {
    &__limiter {
        .select2-selection {
            min-width: 83px;
        }
    }

    &__sorter {
        .select__field {
            min-width: $toolbar__sorter-select-min-width;
        }

        select {
            width: 100%;
        }
    }
}
