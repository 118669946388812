.home {
    &__category-banner {
        .banner__text {
            text-align: center;
        }
    }

    &__banner {
        align-items: center;
    }
}
