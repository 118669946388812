.select2 {
    & &-container &-selection--single &-selection__arrow {
        b {
            transform-origin: center;
        }
    }
}

select {
    font-family: $select__font-family;
    color: $select__field-color;
}
