.heading {
    &--first-level,
    &--second-level {
        font-weight: $font-weight-extra-bold;
    }

    &--third-level,
    &--page {
        font-weight: $font-weight-bold;
    }
}
