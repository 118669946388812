.auth {
    padding: 0 $spacer--semi-medium;

    @include mq($screen-m) {
        padding: 0;
    }

    &__container {
        display: flex;
        flex-flow: column nowrap;

        @include mq($screen-m) {
            display: grid;
            grid-template-columns: 1fr 1px 1fr;
            grid-template-rows: auto;
        }
    }

    &__login-form {
        .account-form {
            padding: 0;

            &__fieldset,
            &__forgot-password {
                margin: 0;
            }

            &__forgot-password,
            &__actions {
                align-self: flex-start;
            }
        }

        @include mq($screen-m) {
            margin: 0 0 0 $spacer--semi-medium;
        }
    }

    &__register-info {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: $border-base;
        padding: 0 0 $spacer--semi-medium;
        margin: 0 0 $spacer--semi-medium;

        @include mq($screen-m) {
            position: relative;
            border-bottom: unset;
            padding: 0;
            margin: 0 $spacer--semi-medium 0 0;
        }
    }

    &__container-divider {
        display: none;

        @include mq($screen-m) {
            display: block;
            height: 100%;
            width: 1px;
            background-color: $gray;
        }
    }

    &__register-button-wrapper &__register-button {
        width: 100%;
        min-width: $account-form__button-min-width;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__cms-content {
        margin: 0 0 $spacer--extra-large;
    }

    &__guest-button-wrapper {
        display: flex;
        justify-content: center;
        margin: $spacer--semi-medium 0 0;
    }

    &__guest-button-wrapper &__guest-button {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
        }
    }
}
