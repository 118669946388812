.compare {
    border: none;

    &__inner {
        margin: 0;
        width: auto;
    }

    &__table {
        border: $compare__border;
        border-collapse: initial;
    }

    &__label {
        position: initial;
    }

    &__row &__label {
        border-width: 0 $border-width-base $border-width-base 0;
    }

    &__row:last-child &__label {
        border-width: 0 $border-width-base 0 0;
    }
}
