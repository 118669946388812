.amrma-product-item {
    &__info-container {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            flex-flow: row nowrap;
        }
    }

    &__name-sku-container {
        align-self: center;
    }

    &__name {
        margin-bottom: $spacer;
        font-weight: $font-weight-bold;
    }

    &__image {
        display: flex;
        margin: 0 $spacer--medium $spacer--medium 0;
    }

    &__image-wrapper,
    &__name-sku-wrapper,
    &__actions-wrapper {
        display: flex;

        &:before {
            display: block;
            content: attr(data-th);
            font-weight: $table__td-font-weight-before;
            margin-right: $table__td-margin-right-before;
            max-width: $table__td-max-width-before;
            width: $table__td-width-before;
            min-width: $table__td-width-before;

            @include mq($screen-m) {
                display: none;
            }
        }
    }

    table &__cell {
        flex-flow: column nowrap;

        &::before {
            content: none;
        }
    }

    &__actions-container {
        flex-grow: 1;

        &:not(.-gray) {
            display: flex;
            flex-flow: column nowrap;

            @include mq($screen-m) {
                flex-flow: row wrap;
            }

            .amrma-return {
                &__item-qty,
                &__item-condition {
                    @include mq($screen-m) {
                        width: calc(30% - #{$spacer--medium});
                        margin-right: $spacer--medium;
                    }
                }

                &__item-reason,
                &__item-resolution {
                    @include mq($screen-m) {
                        width: 70%;
                    }
                }
            }
        }
    }
}
