$footer__background-color                   : $gray-darkest;
$footer__social-list-title-color            : $gray-lighter;
$footer__social-list__link-background       : $gray-darkest;
$footer__social-list__link-background-before: $gray-lighter;
$footer__social-list__link-border           : $border-width-base $border-style-base $gray;
$footer__social-list__icon-link-fill        : $gray-lighter;
$footer__social-list__icon-link-fill-hover  : $gray-darkest;
$footer__links-dropdown-background          : $gray-darkest;
$footer__links-dropdown-list-border         : $spacer--extra-small $border-style-base $gray-dark;
$footer__links-dropdown-list-item-border    : $border-width-base $border-style-base $gray-dark;
$footer__links-label-color                  : $gray-lighter;
$footer__links-label-background             : $gray-darkest;
$footer__link-color                         : $gray-lighter;
$footer__link-color-hover                   : $gray-lighter;
$footer__newsletter-border                  : $spacer--extra-small $border-style-base $gray-dark;
$footer__bottom-bar-border-top              : $border-width-base $border-style-base rgba(110, 117, 119, 0.3);
$footer__newsletter-border                  : $spacer--extra-small $border-style-base rgba(110, 117, 119, 0.3);
$footer__links-dropdown-icon-color-open     : $color-secondary;
$footer__links-dropdown-icon-color-hover    : $color-secondary;
$footer__payments-list-margin               : 0 $spacer--medium;
$footer__bottom-align                       : flex-start;

@import 'footer';
@import 'russell-marine-footer';
