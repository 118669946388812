.search-form {
    &__button {
        background-color: $search-form__button-background;

        &:hover {
            background-color: $search-form__button-background;
        }

        .button__icon {
            fill: $white;
        }
    }
}

.header {
    &__brief-info .brief-info__subtitle {
        color: $gray-dark;
    }
}
