$yotpo-reviews__base-star-color                        : $color-primary !default;
$yotpo-reviews__base-star-color-secondary              : $gray-darker !default;
$yotpo-reviews__yotpo-vendor-large-breakpoint          : 961px !default;

$yotpo-reviews__action-color                           : $font-color-base !default;
$yotpo-reviews__action-color-hover                     : $blue !default;
$yotpo-reviews__action-text-decoration                 : none !default;
$yotpo-reviews__action-text-decoration-hover           : underline !default;
$yotpo-reviews__action-font-size                       : $font-size-medium !default;
$yotpo-reviews__action-font-family                     : $font-family-base !default;
$yotpo-reviews__action-text-transform                  : capitalize !default;

$yotpo-reviews__action-helpful-text-transform          : initial !default;
$yotpo-reviews__action-helpful-color                   : $font-color-base !default;
$yotpo-reviews__action-helpful-icon-size               : $font-size-medium !default;
$yotpo-reviews__action-helpful-icon-color              : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__action-helpful-icon-padding            : $spacer--medium;
$yotpo-reviews__action-helpful-vote-font-size          : $font-size-base !default;
$yotpo-reviews__action-helpful-vote-color              : $font-color-base !default;
$yotpo-reviews__action-helpful-vote-btn-margin         : 0 !default;

$yotpo-reviews__action-font-size                       : $font-size-medium !default;
$yotpo-reviews__action-font-family                     : $font-family-base !default;

$yotpo-reviews__content-title-font-family              : $font-family-secondary !default;
$yotpo-reviews__content-title-font-size                : $font-size-large !default;
$yotpo-reviews__content-title-color                    : $color-primary !default;
$yotpo-reviews__content-title-font-weight              : $font-weight-bold !default;

$yotpo-reviews__content-review-color                   : $gray-dark !default;
$yotpo-reviews__content-review-font-size               : $font-size-base !default;
$yotpo-reviews__content-review-font-family             : $font-family-base !default;
$yotpo-reviews__content-review-line-height             : $font-line-height !default;

$yotpo-reviews__empty-search-clear-btn-color           : $blue !default;

$yotpo-reviews__filter-list-category-color             : $color-secondary !default;
$yotpo-reviews__filter-list-category-color-selected    : $blue !default;
$yotpo-reviews__filter-non-default-color               : $color-secondary !default;
$yotpo-reviews__filter-non-default-color-selected      : $blue !default;

$yotpo-reviews__first-review-icons-display             : none !default;
$yotpo-reviews__first-review-button-display            : initial !default;

$yotpo-reviews__first-question-button-display          : none !default;

$yotpo-reviews__footer-breakpoint                      : $screen-l !default;

$yotpo-reviews__icon-profile-size                      : 48px !default;
$yotpo-reviews__icon-profile-background                : $color-primary !default;
$yotpo-reviews__icon-profile-letter-font-size          : $font-size-extra-large !default;
$yotpo-reviews__icon-profile-letter-font-family        : $font-family-secondary !default;
$yotpo-reviews__icon-profile-letter-color              : $white !default;

$yotpo-reviews__inline-icon-font-size                  : $font-size-medium !default;
$yotpo-reviews__inline-link-font-size                  : $font-size-base !default;
$yotpo-reviews__inline-link-color                      : $color-primary !default;
$yotpo-reviews__inline-link-color-hover                : $color-secondary !default;


$yotpo-reviews__input-post-background-hover            : $gray-dark !default;

$yotpo-reviews__main-average-score-font-size           : $font-size-extra-large !default;
$yotpo-reviews__main-average-score-color               : $color-primary !default;
$yotpo-reviews__main-average-margin                    : 0 $spacer--large 0 0 !default;
$yotpo-reviews__main-average-margin\@medium            : 0 !default;
$yotpo-reviews__main-average-score-line-height         : $font-line-height !default;
$yotpo-reviews__main-average-score-icon-size           : $font-size-large !default;
$yotpo-reviews__main-average-score-icon-color          : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__main-average-reviews-count-font-size   : $font-size-large !default;
$yotpo-reviews__main-average-reviews-count-color       : $color-primary !default;
$yotpo-reviews__main-average-reviews-count-line-height : $font-line-height !default;

$yotpo-reviews__mobile-filter-btn-bg-color             : $button__background !default;
$yotpo-reviews__mobile-filter-btn-text-color           : $button__text-color !default;
$yotpo-reviews__mobile-filter-btn-font-size            : $button__font-size !default;
$yotpo-reviews__mobile-filter-btn-font-family          : $button__font-family !default;

$yotpo-reviews__mobile-filter-text-color               : $font-color-base !default;
$yotpo-reviews__mobile-filter-title-font-size          : $font-size-large !default;
$yotpo-reviews__mobile-filter-title-font-family        : $font-family-secondary !default;
$yotpo-reviews__mobile-filter-title-text-color         : $color-primary !default;

$yotpo-reviews__mobile-filter-selected-color           : $blue !default;
$yotpo-reviews__mobile-filter-title-color              : $color-primary !default;
$yotpo-reviews__mobile-filter-title-font-weight        : $font-weight-bold !default;

$yotpo-reviews__nav-tab-border-color                   : $border-color-secondary !default;
$yotpo-reviews__nav-tab-color                          : $gray !default;
$yotpo-reviews__nav-tab-color--active                  : $color-primary !default;

$yotpo-reviews__listing-review-display                 : block !default;
$yotpo-reviews__listing-icon-size                      : 20px !default;
$yotpo-reviews__listing-review-font-size               : $font-size-base !default;
$yotpo-reviews__listing-review-line-height             : $font-line-height !default;
$yotpo-reviews__listing-review-margin-left             : $spacer !default;

$yotpo-reviews__login-connect-wrapper-width            : 320px !default;
$yotpo-reviews__login-username-top-offset              : 62px !default;
$yotpo-reviews__login-socialize-wrapper-width          : calc(#{$yotpo-reviews__login-connect-wrapper-width} + 10px) !default;

$yotpo-reviews__logo-text-color                        : $color-info !default;
$yotpo-reviews__logo-icon-color                        : $color-info !default;

$yotpo-reviews__pager-page-color                       : $color-primary !default;
$yotpo-reviews__pager-page-color-hover                 : $blue !default;
$yotpo-reviews__pager-page-color-active                : $color-primary !default;
$yotpo-reviews__pager-page-font-size                   : $font-size-medium !default;
$yotpo-reviews__pager-page-icon-size                   : $spacer--extra-large !default;
$yotpo-reviews__pager-page-text-decoration-hover       : underline !default;
$yotpo-reviews__pager-arrow-icon-color                 : $color-primary !default;
$yotpo-reviews__pager-arrow-icon-color-hover           : $white !default;
$yotpo-reviews__pager-arrow-icon-bg-color-hover        : $color-primary !default;
$yotpo-reviews__pager-arrow-font-size                  : $font-size-base !default;
$yotpo-reviews__pager-arrow-font-weight                : $font-weight-bold !default;
$yotpo-reviews__pager-arrow-size                       : $spacer--extra-large !default;

$yotpo-reviews__review-button-icon-color               : $white !default;
$yotpo-reviews__review-icon-size                       : 20px !default;
$yotpo-reviews__star-selected-color                    : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__star-non-selected-color                : $yotpo-reviews__base-star-color-secondary !default;

$yotpo-reviews__thank-you-heart-icon-color             : $red !default;
$yotpo-reviews__thank-you-text-color                   : $color-primary !default;

$yotpo-reviews__username-font-family                   : $font-family-secondary !default;
$yotpo-reviews__username-font-size                     : $font-size-medium !default;
$yotpo-reviews__username-color                         : $color-primary !default;
$yotpo-reviews__username-text-transform                : uppercase !default;
$yotpo-reviews__username-font-weight                   : $font-weight-normal !default;

$yotpo-reviews__date-font-family                       : $font-family-base !default;
$yotpo-reviews__date-font-size                         : $font-size-base !default;
$yotpo-reviews__date-color                             : $color-primary !default;
$yotpo-reviews__date-text-transform                    : uppercase !default;
$yotpo-reviews__date-font-weight                       : $font-weight-normal !default;


// sass-lint:disable no-important
%error {
    color: $color-error;
    font-weight: $font-weight-normal;
}

.page-main {
    .product-view,
    .product-item-info {
        .standalone-bottomline {
            &.star-clickable,
            .star-clickable {
                align-items: center;
            }
            .yotpo-icon {
                font-size: $yotpo-reviews__listing-icon-size;
            }
            // show or not X reviews on product listings
            a {
                display: $yotpo-reviews__listing-review-display;
                font-size: $yotpo-reviews__listing-review-font-size;
                line-height: $yotpo-reviews__listing-review-line-height;
                margin-left: $yotpo-reviews__listing-review-margin-left;
            }
        }

        .yotpo {
            .yotpo-bottomline {
                .yotpo-icon-star,
                .yotpo-icon-half-star,
                .yotpo-icon-empty-star {
                    font-size: $yotpo-reviews__inline-icon-font-size;
                }
            }
        }
    }
    .product-view {
        .yotpo {
            div,
            span,
            p,
            a,
            img,
            i,
            strong,
            sup,
            ul,
            li,
            form,
            label,
            .yotpo-default-button,
            input[type="button"].yotpo-default-button {
                font-family: $font-family-base !important;
                font-size: $font-size-base;
            }

            a {
                color: $yotpo-reviews__inline-link-color !important;
                background: transparent !important;
                font-size: $yotpo-reviews__inline-link-font-size;

                &:hover,
                &:focus {
                    color: $yotpo-reviews__inline-link-color-hover !important;
                    background: transparent !important;
                }
            }

            .yotpo-pager {
                display: flex;
                align-items: center;
                justify-content: center;
                .goTo {
                    @extend .link;
                    position: relative;
                    width: $yotpo-reviews__pager-page-icon-size;
                    height: $yotpo-reviews__pager-page-icon-size;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    color: $yotpo-reviews__pager-page-color;
                    font-size: $yotpo-reviews__pager-page-font-size;
                    &:hover {
                        color: $yotpo-reviews__pager-page-color-hover !important;
                        text-decoration: $yotpo-reviews__pager-page-text-decoration-hover !important;
                    }
                    + .goTo {
                        margin-left: $spacer;
                    }
                    &.yotpo-active {
                        color: $yotpo-reviews__pager-page-color-active !important;
                        &:hover {
                            cursor: default !important;
                            color: $yotpo-reviews__pager-page-color-active !important;
                            text-decoration: none !important;
                        }
                        &:after {
                            @extend .pager__item--current:after;
                        }
                    }
                }

                .yotpo-icon {
                    @extend .link;
                    color: $yotpo-reviews__pager-arrow-icon-color;
                    font-size: $yotpo-reviews__pager-arrow-font-size !important;
                    font-weight: $yotpo-reviews__pager-arrow-font-weight;
                    width: $yotpo-reviews__pager-arrow-size !important;
                    height: $yotpo-reviews__pager-arrow-size !important;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &:before {
                        @extend %button-after:after;
                        opacity: 1;
                        z-index: 1;
                    }
                    &:after {
                        @extend %button-after:after;
                    }
                    &:hover {
                        color: $yotpo-reviews__pager-arrow-icon-color-hover !important;
                        background-color: $yotpo-reviews__pager-arrow-icon-bg-color-hover !important;
                        &:after {
                            opacity: 1;
                            z-index: 10;
                        }
                    }
                    &.yotpo-icon-left-arrow {
                        margin-right: $spacer--medium;
                        &:before {
                            content: url("../images/icons/arrow-left.svg") !important;
                        }
                        &:after {
                            content: url("../images/icons/arrow-left-light.svg") !important;
                        }
                    }
                    &.yotpo-icon-right-arrow {
                        margin-left: $spacer--medium;
                        &:before {
                            content: url("../images/icons/arrow-right.svg") !important;
                        }
                        &:after {
                            content: url("../images/icons/arrow-right-light.svg") !important;
                        }
                    }
                }
            }

            .yotpo-regular-box {
                border: none;
                &.bottom-line-items-container {
                    overflow: hidden;
                    padding-bottom: 0;
                    margin: 0 auto;
                    text-align: center;
                    width: 150px;
                    @include mq($screen-m) {
                        margin: 0 $spacer $spacer--medium;
                        width: auto;
                    }
                    .bottom-line-items {
                        position: relative;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        min-height: 56px;
                        margin-bottom: $spacer--medium;
                    }
                    .avg-score {
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: $yotpo-reviews__main-average-score-font-size !important;
                        color: $yotpo-reviews__main-average-score-color;
                        height: auto;
                        width: auto;
                        margin: $yotpo-reviews__main-average-margin;
                        padding: 0;
                        line-height: $yotpo-reviews__main-average-score-line-height;
                        @include mq($yotpo-reviews__yotpo-vendor-large-breakpoint) {
                            position: relative;
                            margin: $yotpo-reviews__main-average-margin\@medium;
                        }
                    }
                    .rating-stars-container,
                    .reviews-qa-labels-container {
                        margin-left: 60px;
                        @include mq($yotpo-reviews__yotpo-vendor-large-breakpoint) {
                            margin-left: $spacer;
                        }
                    }
                    .reviews-qa-labels-container {
                        position: relative;
                        clear: both;
                        padding-top: 8px;
                    }
                    .yotpo-icon-star {
                        font-size: $yotpo-reviews__main-average-score-icon-size !important;
                        color: $yotpo-reviews__main-average-score-icon-color;
                    }
                    .reviews-qa-label {
                        font-size: $yotpo-reviews__main-average-reviews-count-font-size !important;
                        color: $yotpo-reviews__main-average-reviews-count-color;
                        line-height: $yotpo-reviews__main-average-reviews-count-line-height;
                    }
                }
            }

            .yotpo-default-button,
            input[type="button"].yotpo-default-button {
                @extend .button;

                span.write-question-review-button-text {
                    color: $white;
                    height: auto;
                    line-height: 1;
                }

                &:hover {
                    background-color: $button__background-hover !important;
                    text-decoration: $button__text-decoration-hover !important;

                    &:before {
                        display: $button__before-display-hover !important;
                        transform: $button__before-transform-hover !important;
                    }

                    &:after {
                        display: $button__after-display-hover !important;
                        left: $button__after-position-left-hover !important;
                        opacity: $button__after-opacity-hover !important;
                    }
                }

                &:disabled,
                &[disabled] {
                    background-color: $button__background-disabled !important;
                    border-color: $button__background-disabled !important;
                    color: $button__text-color !important;
                    cursor: not-allowed !important;

                    .icon {
                        fill: $button__text-color !important;
                    }

                    &:hover {
                        background-color: $button__background-disabled !important;

                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            // here arrow icon won't work as they use input element
            .yotpo-default-button.primary-color-btn.yotpo-submit {
                @extend .button;
                &:hover {
                    background-color: $yotpo-reviews__input-post-background-hover !important;
                    text-decoration: $button__text-decoration-hover !important;
                }
            }
            .filters-dropdown .yotpo-dropdown-button {
                .selected {
                    width: calc(100% - 24px);
                    color: $yotpo-reviews__filter-non-default-color;
                }
                .non-default-item-selected {
                    color: $yotpo-reviews__filter-non-default-color-selected;
                }
            }
            .more-filters-btn {
                .more-filters-icon {
                    margin-right: $spacer;
                }
            }
            .yotpo-drop-down-layout .yotpo-dropdown {
                .list-category {
                    color: $yotpo-reviews__filter-list-category-color;
                    .selected-item {
                        color: $yotpo-reviews__filter-non-default-color-selected;
                    }
                }
            }

            .yotpo-nav-content {
                .yotpo-icon.yotpo-icon-magnifying-glass.magnifying-glass-icon {
                    left: 16px;
                    top: 32px;
                }
                .free-text-search-input {
                    padding-left: 48px;
                }
            }
            .yotpo-empty-search-results .empty-search-results-clear-all-btn {
                color: $yotpo-reviews__empty-search-clear-btn-color;
            }

            .yotpo-nav {
                display: flex;
                ul {
                    margin-top: -1px;
                    .yotpo-nav-tab {
                        span {
                            color: $yotpo-reviews__nav-tab-color;
                        }
                        &.yotpo-active {
                            span {
                                color: $yotpo-reviews__nav-tab-color--active;
                            }
                            .yotpo-nav-wrapper {
                                border-color: $yotpo-reviews__nav-tab-border-color;
                            }
                        }
                    }
                }
            }

            .yotpo-review,
            .yotpo-comment,
            .yotpo-question,
            .yotpo-onsite-upload {
                .yotpo-header {
                    .yotpo-icon-profile {
                        width: $yotpo-reviews__icon-profile-size;
                        height: $yotpo-reviews__icon-profile-size;
                        background: $yotpo-reviews__icon-profile-background;
                    }
                    .yotpo-user-letter {
                        color: $yotpo-reviews__icon-profile-letter-color;
                        font-size: $yotpo-reviews__icon-profile-letter-font-size;
                        font-family: $yotpo-reviews__icon-profile-letter-font-family !important;
                    }
                    .yotpo-header-element {
                        .y-label.yotpo-user-name {
                            color: $yotpo-reviews__username-color;
                            font-size: $yotpo-reviews__username-font-size;
                            font-family: $yotpo-reviews__username-font-family !important;
                            text-transform: $yotpo-reviews__username-text-transform;
                            font-weight: $yotpo-reviews__username-font-weight;
                        }
                        .yotpo-review-date {
                            display: none;
                            color: $yotpo-reviews__date-color;
                            font-size: $yotpo-reviews__date-font-size;
                            font-family: $yotpo-reviews__date-font-family !important;
                            font-weight: $yotpo-reviews__date-font-weight;
                            @include mq($yotpo-reviews__footer-breakpoint) {
                                display: block;
                            }
                        }
                    }
                }
                .content-review {
                    color: $yotpo-reviews__content-review-color;
                    font-size: $yotpo-reviews__content-review-font-size;
                    font-family: $yotpo-reviews__content-review-font-family !important;
                    line-height: $yotpo-reviews__content-review-line-height;
                }
                .yotpo-footer {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: $border-base;
                    padding: 0 0 $spacer 0;
                    @include mq($screen-l) {
                        justify-content: flex-end;
                        border: none;
                    }
                    .footer-actions {
                        width: 100%;
                        order: 1;
                        margin-bottom: $spacer--medium;
                        @include mq($screen-l) {
                            margin-right: auto;
                            width: auto;
                        }
                    }
                    .footer-additional-actions {
                        order: 1;
                        width: auto;
                        display: block;
                        @include mq($screen-l) {
                            width: auto;
                            display: none;
                        }
                    }
                    .yotpo-helpful {
                        order: 3;
                        width: auto;
                        margin-left: auto;
                        text-align: right;
                        @include mq($screen-l) {
                            margin-left: 0;
                        }
                        .yotpo-icon {
                            font-size: $yotpo-reviews__action-helpful-icon-size;
                            color: $yotpo-reviews__action-helpful-icon-color;
                            padding: $yotpo-reviews__action-helpful-icon-padding;
                        }
                        .vote-sum {
                            font-size: $yotpo-reviews__action-helpful-vote-font-size;
                            color: $yotpo-reviews__action-helpful-vote-color;
                        }
                        .vote-btn {
                            margin: $yotpo-reviews__action-helpful-vote-btn-margin;
                        }
                    }
                    .label-helpful {
                        color: $yotpo-reviews__action-helpful-color;
                        text-transform: $yotpo-reviews__action-helpful-text-transform;
                    }
                    .yotpo-action,
                    .yotpo-action .social-link {
                        color: $yotpo-reviews__action-color !important;
                        font-size: $yotpo-reviews__action-font-size;
                        font-family: $yotpo-reviews__action-font-family !important;
                        text-transform: $yotpo-reviews__action-text-transform;
                        &:hover {
                            color: $yotpo-reviews__action-color-hover !important;
                            .y-label {
                                text-decoration: $yotpo-reviews__action-text-decoration !important;
                            }
                        }
                    }
                    .yotpo-action .social-link:hover {
                        text-decoration: $yotpo-reviews__action-text-decoration !important;
                    }
                }
                .yotpo-main .content-title {
                    @extend .heading;
                    @extend .heading--second-level;
                    font-size: $yotpo-reviews__content-title-font-size;
                    color: $yotpo-reviews__content-title-color;
                    font-weight: $yotpo-reviews__content-title-font-weight;
                }
            }


            .yotpo-bottomline {
                .yotpo-icon-star,
                .yotpo-icon-half-star,
                .yotpo-icon-empty-star {
                    font-size: $yotpo-reviews__inline-icon-font-size;
                }
            }

            &.yotpo-main-widget,
            .yotpo-modal-dialog {
                .yotpo-thank-you .yotpo-thankyou-header {
                    span {
                        color: $yotpo-reviews__thank-you-text-color;
                    }
                    .yotpo-icon {
                        color: $yotpo-reviews__thank-you-heart-icon-color;
                    }
                }
                .yotpo-logo-link-new {
                    > *,
                    &:hover > * {
                        color: $yotpo-reviews__logo-icon-color;
                    }
                }
                .yotpo-label .yotpo-logo-title {
                    color: $yotpo-reviews__logo-text-color;
                }
                .yotpo-stars-and-sum-reviews {
                    display: flex;
                    align-items: center;
                    min-height: 48px;
                    .yotpo-sum-reviews,
                    .based-on {
                        padding: 0;
                    }
                }
                .yotpo-first-review-stars {
                    display: $yotpo-reviews__first-review-icons-display;
                }

                .write-first-review-button {
                    display: $yotpo-reviews__first-review-button-display;
                }

                .write-first-question-button {
                    display: $yotpo-reviews__first-question-button-display;
                }
            }

            &.yotpo-main-widget {
                .yotpo-icon-write-no-frame::before,
                .yotpo-icon-dialog::before {
                    color: $yotpo-reviews__review-button-icon-color;
                }

                .yotpo-bottomline.yotpo-bottomline-empty-state .write-question-button,
                .yotpo-bottomline.yotpo-bottomline-empty-state .write-review-button,
                .yotpo-icon-btn {
                    display: inline-flex;
                    align-items: center;
                }
                .yotpo-bottomline.yotpo-bottomline-2-boxes .yotpo-bottomline-box-1 {
                    .write-review-button,
                    .write-question-button {
                        display: none;
                    }
                }

                .yotpo-thank-you {
                    .yotpo-icon-btn {
                        .yotpo-icon-button-text {
                            display: none;
                        }
                    }
                }
            }

            .yotpo-review {
                .yotpo-header {
                    .yotpo-review-stars {
                        .yotpo-icon {
                            font-size: $yotpo-reviews__review-icon-size;
                        }
                    }
                }
                .yotpo-read-more {
                    @extend .link;
                    text-decoration: $yotpo-reviews__action-text-decoration !important;
                    &:hover {
                        text-decoration : $yotpo-reviews__action-text-decoration-hover !important;
                    }
                }
            }

            .write-question-button:not(.write-first-question-button),
            .write-button:not(.write-first-review-button) {
                .yotpo-icon {
                    margin-right: 4px;
                }
            }

            .write-review {
                .error-box {
                    background: $color-error;
                }
                .yotpo-default-button.primary-color-btn.yotpo-submit {
                    display: block;
                    width: auto;
                    float: none;
                }
                .yotpo-icon {
                    &:hover {
                        cursor: pointer;
                    }
                }
                .yotpo-footer {
                    @include mq($yotpo-reviews__footer-breakpoint) {
                        display : flex !important;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    @include mq($yotpo-reviews__footer-breakpoint) {
                        justify-content: flex-start;
                    }
                    &.visible {
                        display: flex !important;
                        flex-direction: column;
                        @include mq($yotpo-reviews__footer-breakpoint) {
                            flex-direction: row;
                        }
                    }
                    .socialize-wrapper.visible {
                        display: flex !important;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        flex-direction: column;
                        order: 3;
                        margin-top: $spacer--large;
                        @include mq($yotpo-reviews__footer-breakpoint) {
                            left: auto;
                            right: 0;
                            width: calc(50% - #{$spacer--semi-medium});
                            margin-left: $spacer--semi-medium;
                            margin-top: $yotpo-reviews__login-username-top-offset;
                            padding-top: 0;
                        }
                        .connected {
                            width: 100%;
                            margin: 0 0 $spacer 0;
                            @include mq($screen-m) {
                                padding-left : 48px;
                            }
                        }
                    }
                    .socialize {
                        order: 2;
                        margin-bottom: 0;
                        .y-label {
                            @include mq($screen-m) {
                                padding-top: 0;
                            }
                        }
                        > div {
                            display: flex;
                            .yotpo-icon-btn {
                                width: 48px;
                                height: 48px;
                                margin: 0 $spacer 0 0;
                            }
                            div[data-network="facebook"] {
                                order: 1;
                            }
                            div[data-network="twitter"] {
                                order: 2;
                            }
                            div[data-network="linkedin"] {
                                order: 3;
                            }
                        }
                    }
                }
                .connect-wrapper {
                    padding: 0;
                    @include mq($screen-m) {
                        width: 50%;
                    }

                    .form-element {
                        padding: 0;
                        width: 100%;
                        .form-input-error {
                            @extend %error;
                        }
                    }
                }

                .yotpo-header {
                    .y-label {
                        .form-input-error {
                            @extend %error;
                        }
                    }

                    .yotpo-header-title {
                        @extend .heading;
                        @extend .heading--second-level;
                    }

                    .yotpo-icon {
                        color: $yellow;
                    }
                }

                .submit-button {
                    float: none;
                    .form-input-error {
                        @extend %error;
                        margin: $spacer--medium 0;
                    }
                }

                .socialize-wrapper {
                    position: relative;
                    width: $yotpo-reviews__login-socialize-wrapper-width;
                    .yotpo-or {
                        margin: $spacer 0;
                        text-align: left;
                        @include mq($screen-m) {
                            margin: 0 0 41px; // to make social icons align with email input
                            width : auto;
                        }
                    }
                }
            }

            .form-group .form-element .form-input-error {
                @extend %error;
            }

            .yotpo-regular-box {
                margin: 0 $spacer $spacer--medium;
            }

            input,
            textarea {
                @extend .input__field;
            }

            textarea {
                @extend .input__field--textarea;
            }
        }

        .yotpoBottomLine {
            order: -1;
            a {
                margin-right: $spacer--small;
            }

            .yotpo-stars {
                margin-right: $spacer;
            }

            .yotpo-icon-double-bubble {
                margin-right: 4px;
            }
        }

        .bottomLine,
        .QABottomLine {
            margin-bottom: $spacer;
        }

        .y-label {
            &.yotpo-header-title {
                @extend .heading;
                @extend .heading--second-level;
            }
        }

        &__tabs {
            .yotpo {
                .promoted-products-box {
                    .yotpo-promoted-products {
                        margin: 0;
                    }

                    .promoted-products .yotpo-promoted-product {
                        .yotpo-product {
                            &-link {
                                display: inline-flex;
                                flex-direction: column;
                            }

                            &-image {
                                img {
                                    margin: 0 auto;
                                }
                            }

                            &-name {
                                white-space: pre-wrap;
                                margin-bottom: $spacer;
                            }
                        }
                    }
                }
            }
        }

        .write-question-review-buttons-container {
            float: none;
            display: flex;
            justify-content: flex-end;
        }
    }
}

// styles for modal added before body closing tag
.catalog-product-view {
    .mobile-filters-modal {
        .mobile-filters-footer-btn {
            background-color: $yotpo-reviews__mobile-filter-btn-bg-color;
            .footer-text {
                background-color: $yotpo-reviews__mobile-filter-btn-bg-color;
                color: $yotpo-reviews__mobile-filter-btn-text-color;
                font-size: $yotpo-reviews__mobile-filter-btn-font-size;
                font-family: $yotpo-reviews__mobile-filter-btn-font-family !important;
            }
        }
        .mobile-filters-modal-title {
            .title-text {
                font-size: $yotpo-reviews__mobile-filter-title-font-size;
                color: $yotpo-reviews__mobile-filter-title-text-color;
            }
        }
        .mobile-filters-container {
            .mobile-single-filter {
                .mobile-filter-radio-buttons .mobile-filter-radio-button {
                    .radio-selected-color {
                        font-family: $font-family-base !important;
                        color: $yotpo-reviews__mobile-filter-text-color;
                        .yotpo-icon {
                            color : $yotpo-reviews__star-non-selected-color;
                        }
                    }
                    .radio-selected-star-color {
                        &.yotpo-icon-star {
                            color : $yotpo-reviews__star-selected-color;
                        }
                    }
                }
                .mobile-filter-title {
                    font-family: $font-family-base !important;
                    color: $yotpo-reviews__mobile-filter-title-color;
                    font-weight: $yotpo-reviews__mobile-filter-title-font-weight;
                }
            }
        }
    }

}
//sass-lint:enable no-important
