.teaser {
    &--secondary {
        flex-flow: column;

        @include mq($screen-m) {
            flex-flow: row-reverse;
        }
    }

    &__content {
        width: 100%;

        @include mq($screen-m) {
            width: calc((1 / 2) * 100%);
        }

        @include mq($screen-l) {
            width: calc((5 / 12) * 100%);
        }

        @include mq($screen-xl) {
            width: calc((4 / 12) * 100%);
        }
    }

    &__content-block {
        align-items: flex-start;

        .content-block {
            padding: 0;

            &__column {
                position: relative;
                display: flex;
                align-items: center;
                flex-flow: column nowrap;

                @include mq($screen-m) {
                    align-items: flex-start;
                }
            }

            &__logo {
                position: absolute;
                top: -100px;
                left: 50%;
                transform: translateX(-50%);
                margin-bottom: 0;
                justify-content: flex-start;

                @include mq($screen-m) {
                    position: static;
                    transform: unset;
                    margin-bottom: $spacer--medium;
                }

                @include mq($screen-xl) {
                    margin-bottom: 80px;
                }
            }

            &__heading {
                margin: 0;
            }

            &__description {
                margin-bottom: $spacer--semi-large;
            }

            &__heading,
            &__description {
                text-align: center;
                color: $white;

                @include mq($screen-m) {
                    text-align: start;
                }
            }

            &__button {
                padding-top: $spacer--semi-medium;
                padding-bottom: $spacer--semi-medium;
            }
        }
    }

    &__image {
        @include mq($screen-l) {
            width: calc((7 / 12) * 100%);
        }

        @include mq($screen-xl) {
            width: calc((8 / 12) * 100%);
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
