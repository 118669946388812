// Russell Marine variables

// Colors
$red                   : #cd161b;
$green                 : #0e8a00;
$light-navy-blue       : #1c5896;

// Color scheme
$color-accent          : $red;

// Color scheme
$color-accent          : $red;

// Typography
$font-family-sans-serif: 'Work Sans', sans-serif;

$font-family-base      : $font-family-sans-serif;
$font-family-secondary : $font-family-sans-serif;

$font-weight-extra-bold: 800;
