.footer {
    &__social-handler {
        &:before {
            background: $gray-dark;
        }
    }

    &__social-list {
        .list__item {
            .button {
                &:before {
                    background-color: $footer__social-list__link-background-before;
                }
            }

            .button--icon-border {
                background-color: $footer__social-list__link-background;
                border: $footer__social-list__link-border;

                .icon {
                    fill: $footer__social-list__icon-link-fill;
                }
            }

            &:hover {
                .icon {
                    fill: $footer__social-list__icon-link-fill-hover;
                }
            }
        }
    }

    &__bottom-bar {
        border-top: $footer__bottom-bar-border-top;
    }

    &__bottom-bar-handler {
        @include mq($screen-m) {
            padding: 0 $spacer--small;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        @include mq($screen-l) {
            justify-content: space-around;
        }
    }

    &__copyright {
        margin: 0 $spacer--medium;
        text-align: initial;

        @include mq($screen-m) {
            flex-basis: 40%;
            margin: 0;
        }

        @include mq($screen-l) {
            flex-basis: auto;
        }
    }

    &__payments-list {
        justify-content: flex-start;

        @include mq($screen-m) {
            flex-basis: 60%;
            justify-content: flex-end;
            flex-wrap: nowrap;
        }

        @include mq($screen-l) {
            flex-basis: auto;
            justify-content: flex-start;
        }
    }
}
