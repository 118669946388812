.newsletter {
    .checkbox {
        &__icon {
            fill: $black;
        }

        &__label {
            &::after {
                background-color: white;
            }
        }
    }
}
