$webforms_please-wait-padding        : $spacer $spacer--medium !default;
$webforms_please-wait-align-items    : center !default;
$webforms_please-wait-justify-content: center !default;
$webforms_please-wait-height         : 100% !default;

.webforms {
    .form {
        .actions-toolbar {
            @extend .dashboard-actions-toolbar;
            margin-top: 15px;
        }

        .actions-toolbar,
        .fieldset,
        .field {
            padding: 0;
            float: none;
        }

        .validation-advice__number {
            margin: -$spacer 0 $spacer--medium;
        }

        .please-wait {
            display: flex;
            padding: $webforms_please-wait-padding;
            align-items: $webforms_please-wait-align-items;
            justify-content: $webforms_please-wait-justify-content;
            height: $webforms_please-wait-height;
        }
    }

    .webforms-field {
        float: none;
    }

    &__modal-container {
        overflow: auto;
    }

    &__modal-content {
        padding-top: $spacer--semi-medium;
    }

    // critical input extends in Magento_Theme/styles/modules/_input-extends.scss
    .type-select,
    .type-country {
        @extend .select--native;
    }
}
