.mega-menu {
    $root: &;
    &__item {
        flex-grow: 1;

        &:last-of-type {
            #{$root}__link {
                &::after {
                    display: none;
                }
            }
        }
    }

    &__link {
        flex-grow: 1;
        justify-content: center;

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            width: $border-width-base;
            height: calc(#{$mega-menu__link-height} - #{$spacer--medium} * 2);
            background-color: $gray-dark;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
