.section {
    &__heading {
        text-align: center;

        &::before,
        &::after {
            content: "";
            display: block;
            height: $spacer--extra-small;
            background-color: $color-accent;
            width: calc(2 * #{$spacer--extra-large});
            margin: 0 $spacer;
        }
    }
}
