@include mq($screen-l) {
    .total-step-1 {
        .progress-bar__item {
            &:last-child {
                .progress-bar__label {
                    transform: translateX(50%);
                }
            }
        }
    }
}