    
.banner {
    display: flex;
    flex-flow: column nowrap;

    &__content {
        justify-content: center;
        flex-grow: 1;
        width: 100%;
    }
    
    &__heading-wrapper {
        align-items: center;
    }
}
