.product-view {
    &__price-label {
        color: $gray-dark;
    }

    &__qty {
        .label {
            &::after {
                content: ':';
                position: absolute;
            }
        }
    }

    &__buttons {
        flex-flow: row;
    }

    &__add-to-cart {
        flex: 1 100%;

        &.button--add-to {
            .button__text {
                display: block;
            }
        }
    }

    &__addtowishlist,
    &__extra-actions {
        display: flex;
        flex: 0;
    }

    &__extra-actions,
    &__addtowishlist {
        margin: 0 0 0 $spacer;
    }

    &__extra-actions {
        align-items: unset;

        & .product-view__button {
            min-height: $product-view__button-min-size;
            min-width: $product-view__button-min-size;
        }

        .button__text {
            display: none;
        }
    }

    &__brief {
        .brief-info {
            &__subtitle {
                color: $gray-dark;
            }
        }
    }

    &__yotpo-summary {
        display: flex;
    }
}
