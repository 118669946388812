@font-face {
    font-family: 'Work Sans';
    src:
    url('../fonts/work-sans-regular.woff2') format('woff2'),
    url('../fonts/work-sans-regular.woff') format('woff'),
    url('../fonts/work-sans-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src:
    url('../fonts/work-sans-medium.woff2') format('woff2'),
    url('../fonts/work-sans-medium.woff') format('woff'),
    url('../fonts/work-sans-medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src:
    url('../fonts/work-sans-bold.woff2') format('woff2'),
    url('../fonts/work-sans-bold.woff') format('woff'),
    url('../fonts/work-sans-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src:
    url('../fonts/work-sans-extra-bold.woff2') format('woff2'),
    url('../fonts/work-sans-extra-bold.woff') format('woff'),
    url('../fonts/work-sans-extra-bold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

